import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import AestheticsTestimonials from "../../components/AestheticsTestimonials";
import Badge from "../../components/badge";
import GetStartedButton from "../../components/button";

const Aesthetics = () => {
  return (
    <Layout map={false}>
      <SEO
        title="Shockwave Therapy for Cellulite, Body Contouring & Skin Tightening | Multnomah Medical Clinic"
        description="Discover advanced shockwave treatments for cellulite reduction, body contouring, and skin tightening at Multnomah Medical Clinic. Transform your look today."
      />
      <div className="flex justify-center text-center">
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-4">
          <div className="sm:grid sm:grid-cols-5 grid-cols-1 rounded-lg gap-4 mb-10">
            <div className="col-span-3 row-1-thing">
              <h1 className="text-3xl text-gray-900 font-semibold leading-snug">
                Reshape Your Contours, Eliminate Cellulite with Shockwave
                Therapy
              </h1>
              <div className="flex gap-3 mt-2 mb-4 items-center flex-wrap">
                <Badge classes="bg-blue-300 text-no-wrap">
                  Cellulite reduction
                </Badge>
                <Badge classes="bg-blue-300">Body contouring</Badge>
                <Badge classes="bg-blue-300">Skin tightening</Badge>
                <Badge classes="bg-blue-300">Facials</Badge>

                <div
                  className="font-semibold hidden sm:visible"
                  style={{ fontSize: "14px" }}
                >
                  + MORE
                </div>
              </div>
              <div className="text-lg mb-6">
                Introducing a groundbreaking approach to cellulite reduction and
                body contouring using focused shockwave therapy.
              </div>
              <div className="text-lg mb-6">
                Our method is entirely non-invasive, requiring no surgery, no
                injections, and no medications. Experience visible, lasting
                results with a treatment that's clinically proven to be
                effective globally.
              </div>
              <GetStartedButton linkTo="/contact-us">
                Book an appointment
              </GetStartedButton>
            </div>
            <div
              className="col-span-2 w-full flex row-1-thing mb-4"
              style={{ minHeight: "300px" }}
            >
              <StaticImage
                width="100%"
                aspectRatio={1 / 1}
                objectFit="cover"
                layout="constrained"
                src="../../images/aesthetics/thigh_from_below.jpeg"
                className="w-full rounded-lg mt-4"
                style={{ minHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-6  pt-4 pb-10 bg-blue-50">
          <div className="text-2xl font-semibold mb-2">Popular treatments</div>
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-12 sm:gap-y-1 gap-y-4">
            <div>
              <div className="font-medium text-lg mb-2">
                Cellulite Reduction
              </div>
              <div style={{ textWrap: "balanced" }}>
                Focused shockwaves target and rejuvenate skin layers, improving
                circulation and promoting fat cell breakdown. The result? A
                significant reduction in cellulite appearance, leading to
                smoother, more toned skin.
              </div>
            </div>
            <div>
              {" "}
              <div className="font-medium text-lg mb-2">Body Contouring</div>
              <div style={{ textWrap: "balanced" }}>
                Non-invasive shockwave therapy reshapes and contours the body by
                eliminating stubborn fat pockets and tightening the skin.
                Achieve a slimmer silhouette without surgery or downtime.
              </div>
            </div>
            <div>
              <div className="font-medium text-lg mb-2">Skin Tightening</div>
              <div style={{ textWrap: "balanced" }}>
                Revitalize and firm your skin with targeted shockwave therapy.
                This treatment boosts collagen synthesis, tightening and
                smoothing the skin for a youthful, rejuvenated appearance.
              </div>
            </div>
          </div>
        </div>
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-4 text-2xl font-semibold pt-4 pb-10">
          <div className="mb-6">How shockwave therapy works</div>
          <div className="grid sm:grid-cols-4 grid-cols-1 gap-8">
            <div className="col-span-2 flex flex-col gap-2">
              <div>
                <div className="text-sm font-medium">
                  1. Targets problem areas
                </div>
                <div className="text-sm mt-3 font-normal">
                  Our advanced shockwave technology precisely targets cellulite
                  and fat deposits. Customizable settings allow for tailored
                  treatments that address your specific areas of concern,
                  optimizing results.
                </div>
              </div>

              <div>
                <div className="text-sm font-medium mt-3">
                  2. Stimulates Natural Processes
                </div>
                <div className="text-sm mt-3 font-normal">
                  The therapy boosts blood circulation and lymphatic drainage,
                  facilitating the breakdown of fat cells. It also triggers the
                  body's natural regenerative mechanisms, promoting skin
                  tightening and tissue healing.
                </div>
              </div>
              <div>
                <div className="text-sm font-medium mt-3">
                  3. Enhances Skin Appearance
                </div>
                <div className="text-sm mt-3 font-normal">
                  Regular sessions lead to a noticeable reduction in cellulite
                  and improved skin texture. The combined effects of fat
                  reduction, increased circulation, and cellular regeneration
                  result in smoother, firmer skin and a more sculpted body
                  silhouette.
                </div>
              </div>
            </div>
            <div className="">
              {" "}
              {/*<iframe
                className="w-full embed-vid-player"
                src="https://youtube.com/shorts/0W-NR3cIhxw?si=USEB0S2-L_HeA5Hv"
                title="No More Joint Pain, No More Neuropathy. Soft Tissue Treatment Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
  ></iframe>*/}
              {
                <iframe
                  className="embed-vid-player w-full"
                  src="https://iframe.mediadelivery.net/embed/390189/abd91757-cdc1-4642-8d9c-3f32228faa47"
                  title="Stomach contouring with shockwave therapy"
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              }
            </div>
          </div>
        </div>
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] bg-blue-50 gap-4  pt-4 pb-10">
          <div className="mb-6 text-2xl font-semibold ">
            What sets our shockwave apart
          </div>
          <div className="max-w-2xl flex gap-4 flex-col">
            <div>
              The results from shockwave therapy highly depend on the equipment
              used. At our clinic, we have the highest quality equipment from
              Storz Medical.
            </div>
            <div>
              Additionally, while most clinics only use radial shockwave
              therapy, we use a combination of Radial + Focused shockwave for
              the best results.
            </div>
            <div className="font-semibold">Radial Shockwave</div>
            <div>
              The most common form of shockwave therapy available. It produces
              pressure waves that impact on the surface of the skin and travel
              downwards into the deeper tissues, offering deep tissue effects
              that cannot be achieved with manual massage or tools.
            </div>
            <div className="font-semibold">Focused Shockwave</div>
            <div>
              Focused shockwave is used to precisely target and penetrate deeper
              areas of the body. Focused Shockwave Therapy (FSWT) has a very
              high penetrative power and can deliver a precise beam of shockwave
              energy into a small area and specific part of the target tissue
              region.
            </div>
          </div>
        </div>
        {/*<div className="bg-blue-50 font-semibold pt-4 pb-10 text-2xl text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-4">
          Frequently asked questions
        </div>*/}
      </div>
      <div className="text-left w-full">
        <AestheticsTestimonials />
      </div>
    </Layout>
  );
};

export default Aesthetics;

//const vidRef = useRef(null)
//const [mouseInIframe, setMouseInIframe] = useState(false)

// Log video interactions
/*useEffect(() => {
    const setMouseIn = () => setMouseInIframe(true)
    const setMouseOut = () => setMouseInIframe(false)
    const logVideoClick = () => {
      if (mouseInIframe === true) {
        logEvent(firebaseContext.firebase, "video_interaction")
        typeof window !== "undefined" &&
          window.gtag('event', 'video_play', { send_to: "UA-154763728-1"})
      }
    }
    if (vidRef.current === null) {
      return
    } else {
      const localVidRef = vidRef.current
      localVidRef.addEventListener("mouseenter", setMouseIn)
      localVidRef.addEventListener("mouseout", setMouseOut)
      window.addEventListener("blur", logVideoClick)
      return () => {
        localVidRef.removeEventListener("mouseenter", setMouseIn)
        localVidRef.removeEventListener("mouseout", setMouseOut)
        window.removeEventListener("blur", logVideoClick)
      }
    }
  }, [vidRef, mouseInIframe])*/
